<template>
    <div id="app"
         class="fixed-sn  ">
        <router-view></router-view>

    </div>
</template>
<style  >
body {
  font-weight: 300;
  background-color: #eee !important;
}
</style>
<script>
export default {
  name: "App",
  data() {
    return {
      activePage: "Dashboard v.1",
    };
  },
  mounted() {
    this.activePage = this.$route.name;
  },
  updated() {
    this.activePage = this.$route.name;
  },
};
</script>
