<template>
    <div class="white-skin mdb-admin-sidenav">
        <mdb-side-nav logo="/img/logo.png"
                      sideNavClass="sn-bg-4"
                      href="/"
                      mask="strong"
                      :OpenedFromOutside.sync="toggle">
            <mdb-side-nav-nav v-if="menu!=''">
                <section v-for="(m,mk) in menu"
                         :key="`menu_${mk}`">
                    <mdb-side-nav-cat v-if="m.submenu"
                                      :name="m.name"
                                      :icon="m.icon?m.icon:''">
                        <mdb-side-nav-item v-for="(sm,smk) in m.submenu"
                                           :icon="sm.icon?sm.icon:''"
                                           :to="sm.link?sm.link:''"
                                           @click.native="width < 1440 && toggleSideNav(false)"
                                           :key="`sm_${smk}`">{{sm.name}}</mdb-side-nav-item>
                    </mdb-side-nav-cat>
                    <mdb-side-nav-item v-else
                                       header
                                       @click.native="width < 1440 && toggleSideNav(false)"
                                       :icon="m.icon?m.icon:''"
                                       :to="m.link?m.link:''">{{m.name}}</mdb-side-nav-item>
                </section>
            </mdb-side-nav-nav>
            <span style="color:#999"
                  v-if="menu==''">清單正在取得中...</span>
        </mdb-side-nav>
    </div>
</template>

<script>
import {
  mdbSideNav,
  mdbSideNavNav,
  mdbSideNavCat,
  mdbSideNavItem,
  waves,
} from "mdbvue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "SideNav",
  props: {
    waves: {
      type: Boolean,
      default: true,
    },
    open: {
      type: Boolean,
    },
  },
  components: {
    mdbSideNav,
    mdbSideNavNav,
    mdbSideNavCat,
    mdbSideNavItem,
  },
  data() {
    return {
      active: 0,
      elHeight: 0,
      windowHeight: 0,
      toggle: true,
      width: 0,
    };
  },
  computed: mapGetters(["sidenav", "menu"]),
  methods: {
    ...mapActions(["toggleSideNav"]),
    afterEnter(el) {
      el.style.maxHeight = "1000px";
    },
    beforeLeave(el) {
      el.style.maxHeight = 0;
    },
    setWidth() {
      this.width = window.innerWidth;
    },
  },
  mounted() {
    if (this.menu == "") {
      this.$store.dispatch("getmenu");
    }
    this.setWidth();
    window.addEventListener("resize", this.setWidth);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setWidth);
  },
  watch: {
    toggle(value) {
      this.toggleSideNav(value);
    },
    sidenav(value) {
      this.toggle = value;
    },
    width(value) {
      if (value < 1440) {
        this.toggle = false;
      } else this.toggle = true;
    },
  },
  mixins: [waves],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style>
.side-nav {
  opacity: 1 !important;
  transition: transform 0.3s linear !important;
}
</style>
