<template>
    <div class="">
        <!--SideNav-->
        <side-nav />
        <!--/SideNav-->
        <div class="flexible-content">
            <!--Navbar-->
            <navbar :page="activePage" />
            <!--/Navbar-->

            <!--MainContent-->
            <main class="mdb-skin">
                <mdb-container fluid>
                    <router-view></router-view>
                </mdb-container>
            </main>
            <!--/MainContent-->
        </div>
    </div>
</template>
<script>
import SideNav from "./SideNav";
import Navbar from "./Navbar";

import { mdbContainer } from "mdbvue";
export default {
  name: "App",
  components: { mdbContainer, SideNav, Navbar },
  data() {
    return {
      activePage: "Dashboard v.1",
    };
  },
  mounted() {
    this.activePage = this.$route.name;
  },
  updated() {
    this.activePage = this.$route.name;
  },
};
</script>