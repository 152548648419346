import swal from "sweetalert2";
import cookie from "vue-cookies";
import router from "../../router/router.js";

export default {
    state: {
        login_token: "",
        id: "",
        name: "",
    },
    mutations: {
        set_user(state, user_data) {
            state.id = user_data.id;
            state.login_token = user_data.login_token;
            state.name = user_data.name;
        },
    },
    actions: {
        login(context, data) {

            context
                .dispatch("post_form", {
                    payload: {
                        url: "auth/login_process",
                        data: data,
                    },
                })
                .then((res) => {
                    if (res.data.status == "login_success") {
                        cookie.set("login_token", res.data.token);
                        cookie.set("admin_id", res.data.admin.id);
                        cookie.set("admin_name", res.data.admin.name);
                        context.commit(
                            "set_user",
                            Object.assign(res.data.admin, {
                                login_token: res.data.token,
                            })
                        );
                    }
                    data.callback(res)
                });
        },
        logout(context) {
            context.dispatch("clear_cookie");
            swal.fire({
                title: "已為您登出",
                timer: 1000,
                icon: "success",
                showConfirmButton: false,
            }).then(() => {
                router.push({
                    path: "/login",
                });
            });
        },
        clear_cookie(context) {
            cookie.remove("login_token");
            cookie.remove("admin_id");
            cookie.remove("admin_name");
            context.commit("set_user", {
                id: "",
                login_token: "",
                name: "",
            });
        }
    },
};